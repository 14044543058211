import _ from "underscore";
import {awaitWrap} from '@/page/admin/company-management/staff-management/common/unit'
import {hidePolicyLabelCompanyIdList} from "@/lib/other/index.js";
// 获取列表
import consumer_hotel_search_hotelList from "@/lib/data-service/haolv-default/consumer_hotel_search_hotelList.js";
import consumer_hotel_search_hotelListV2 from '@/lib/data-service/haolv-default/consumer_hotel_search_hotelListV2';
//酒店推荐列表
import consumer_hotel_search_hotelListRecommendV2
    from "@/lib/data-service/haolv-default/consumer_hotel_search_hotelListRecommendV2.js";
import consumer_hotel_search_hotelListRecommendV3 from '@/lib/data-service/haolv-default/consumer_hotel_search_hotelListRecommendV3';
// 获取最低价
import consumer_hotel_search_getHotelMinPrice
    from "@/lib/data-service/haolv-default/consumer_hotel_search_getHotelMinPrice.js";
// 差标提示
import consumer_web_policyConfig_travelPolicyTips
    from "@/lib/data-service/haolv-default/consumer_web_policyConfig_travelPolicyTips.js";
// 出差详情
import consumer_journey_getEvectionDetail from "@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail.js";
import consumer_web_policyConfig_myTravelPolicy
    from "@/lib/data-service/haolv-default/consumer_web_policyConfig_myTravelPolicy.js";
import consumer_apply_myApplyList from '@/lib/data-service/haolv-default/consumer_apply_myApplyList'; // 我的申请列表
import consumer_journey_saveOrderMsg from '@/lib/data-service/haolv-default/consumer_journey_saveOrderMsg'; // 我的申请列表

import SearchBox from './components/SearchBox.vue';
import FilterBox from './components/FilterBox.vue';
import Goods from './components/Goods.vue';
import GoodsEmpty from './components/GoodsEmpty.vue';
import EvectionStandard from '@/component/evectionStandard/import_latest_version_module';
// import TravelPopup from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/travel-popup/1.0.0/index.vue'
import TravelPopup from '@/component/travel-popup/1.0.0/index.vue'

import aes_decrypt from 'nllib/src/common/aes/aes_decrypt';
import aes_encrypt from 'nllib/src/common/aes/aes_encrypt';

import getEvectionAllType
    from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_evection_logic_judge_option/1.1.1'//获取出差类型
import refund_change_policy_get from '@/lib/data-service/haolv-default/consumer_t-tp-refund-change-policy_get'
import getJourneyOptionsCanBeModified
    from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_journey_options_can_be_modified/index.js'
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info' // 获取个人信息

export default {
    data() {
        return {
            companyId: 0,
            isShowStandard: true,
            cityNotValid: false,//当前城市是否可用于搜索酒店
            cityNotValidMsg: '',//当前城市不可搜索酒店的提示语
            hidePolicyLabelCompanyIdList: hidePolicyLabelCompanyIdList,
            evectionDetailType: '', // '001':因公出差带出差单, '002': 因公出差不带出差单, '101':因私出差
            businessRuleControl: 1, // 因公出差规则 1：出差单审批 2：预订审批
            bookUpperBound: 6,
            unlimitedJourney: 1, // 是否不限行程 0：否 1：是
            allowChangeJourneyTime: 1, // 是否允许修改行程时间 0：否 1：是
            changeEvectionCity: 0, // 是否可选临近城市 0:否, 1:是
            query: {
                cityId: '',
                cityName: '',
                applyId: '',
                checkInDate: '',
                checkOutDate: '',
                keyword: '',//用来查询的keyword入参
                filterType: '',
                hotelBrand: '',
                hotelStar: '',
                priceSort: '',
                currentPage: 1,
                pageSize: 10,
                productType: 3,
                d: '',
                pt: '',//用来查询的pt入参
                differenceEnable: 0,//是否符合差标
                agreementEnable: 0,//是否是协议酒店
                pt_from_type: '000'
            },
            evectionType: 1,//为了适应getJourneyOptionsCanBeModified
            workTravelType: 1,//为了适应getJourneyOptionsCanBeModified
            journeyType: 0,//为了适应getJourneyOptionsCanBeModified
            landmark: '',//筛选中选中的位置区域名字
            travelDate: [],//不限行程订单出行可选时间段
            total: 0,
            pageCount: 0,
            // 出差详情
            evectionDetail: {},
            showEditPeople: false,
            allPeopleList: [],
            peopleCheckList: [],
            // 差标提示
            travelPolicyTips: {},
            // SearchBox
            cityInfo: {},
            date: [],
            keywordObj: {//keyword组件返回的值
                pt: '',
                keyword: '',
                filterType: 0,
            },
            keyword: '',//keyword组件显示的keyword字符串
            // Goods
            list: [],
            recommendList: [],//推荐酒店列表
            // GoodsEmpty
            searchLoading: true,
            // 因公出行
            popoverLoading: false,
            myTravelPolicy: {},
            evectionRequired: 0,
            isNoMore: false,//是否已加载到最后
            hotelDateArr: [],
            timeOptionRange: null,
            cityCanEdit: true,
            timeCanEdit: true,
            shareJourneyId: 0,
            shareEvectionNo: '',
        };
    },
    components: {
        SearchBox,
        FilterBox,
        Goods,
        GoodsEmpty,
        EvectionStandard,
        TravelPopup
    },
    watch: {
        hotelDateArr(newVal) {
            if (newVal === null || newVal.length === 0) {
                return
            }
            if (newVal.length > 0) {
                this.query.checkInDate = newVal[0];
                this.query.checkOutDate = newVal.length === 2 ? newVal[1] : '';
            }
        }
    },
    methods: {
        //获取用户信息
        async getUserInfo() {
            let res = await get_user_info();
            this.companyId = res.datas.companyId;
            this.isShowStandard = _.indexOf(hidePolicyLabelCompanyIdList, this.companyId) > -1? false : true;
        },
        //获取出差类型
        getEvectionType() {
            let evectionParams = {
                vm: this,
                situation: '003'
            }
            getEvectionAllType(evectionParams).then(evectionQuery => {
                this.evectionDetailType = evectionQuery.evectionDetailType;
            })
        },
        async refundChangePolicyGet() {
            let res = await refund_change_policy_get();
            this.businessRuleControl = res.datas.businessRuleControl;
            this.evectionRequired = res.datas.evectionRequired;
            this.bookUpperBound = res.datas.bookUpperBound;
            this.allowChangeJourneyTime = res.datas.allowChangeJourneyTime;
            this.unlimitedJourney = res.datas.unlimitedJourney;
            this.changeEvectionCity = res.datas.changeEvectionCity;
            // this.businessRuleControl = res.datas.businessRuleControl;
        },
        canChangeFactor() {
            getJourneyOptionsCanBeModified({
                situation: '200',
                vm: this
            }).then(res => {
                this.cityCanEdit = res.cityCanEdit
                this.timeCanEdit = res.timeCanEdit
            })
        },
        setStandard() {
            if (!this.$refs.standard) {
                setTimeout(() => {
                    this.setStandard()
                }, 500)
                return
            }
            this.$refs.standard.init({
                evectionNo: this.query.evectionNo,
                businessType: 3,
                hotelCityId: this.query.cityId
            })
        },
        initQuery() {
            let query = this.$route.query
            this.evectionType = parseInt(query.evectionType);
            this.workTravelType = parseInt(query.workTravelType);
            this.journeyType = parseInt(query.journeyType);
            this.shareJourneyId = parseInt(query.shareJourneyId || 0);
            this.shareEvectionNo = query.shareEvectionNo || '';
            if (this.evectionType === 1) {
                const {
                    checkInDate,
                    checkOutDate,
                    filterType,
                    keyword,
                    pt,
                } = query;
                this.date = [checkInDate, checkOutDate];
                this.keyword = keyword;
                this.keywordObj = {
                    filterType,
                    keyword,
                    pt,
                }

                this.query = Object.assign(this.query, {
                    cityId: Number(query.cityId),
                    cityName: query.cityName,
                    checkInDate,
                    checkOutDate
                }, query);

                if (filterType === 1) {
                    this.query.keyword = ''
                }
            }

            if (this.evectionType === 2) {
                const key = 'params';
                const params = aes_decrypt({
                    data: query.params,
                    key,
                });
                query = Object.assign({}, query, JSON.parse(params))
                const {
                    cityName,
                    checkInDate,
                    checkOutDate,
                    departTime,
                    returnTime,
                    filterType,
                    keyword,
                    pt,
                } = query;
                this.date = [checkInDate, checkOutDate];
                this.travelDate = [departTime, returnTime];
                this.keyword = keyword;
                this.keywordObj = {
                    filterType,
                    keyword,
                    pt,
                }
                this.query = Object.assign(this.query, {
                    cityId: Number(query.cityId),
                    applyId: Number(query.applyId),
                    cityName,
                    checkInDate,
                    checkOutDate,
                    departTime,
                    returnTime,
                }, query);
                if (filterType === 1) {
                    this.landmark = JSON.parse(JSON.stringify(this.query.keyword));
                    this.query.keyword = ''
                }
            }
        },
        initSearchBoxInfo() {
            const {cityId, cityName} = this.query;
            this.cityInfo = {
                cityId,
                cityName
            };
        },
        checkIsNoMore() {
            let isNoMore = false;
            if (this.query.currentPage >= this.pageCount || this.list.length >= 1001) {
                isNoMore = true;
            }
            this.isNoMore = isNoMore;
        },
        async setList() {
            console.log('进入刷新接口');
            if (!this.isNoMore) {
                this.searchLoading = true;
                try {
                    // 关于关键词。 如果关键词选择的是地标类型，则不用传入到接口，只传坐标，如果关键词是纯关键词或者是酒店类型，则需要传入到接口，不传坐标
                    let hotelStar = [];
                    if (this.query.hotelStar) {
                        this.query.hotelStar.split(',').forEach(value => {
                            hotelStar.push(parseInt(value))
                        })
                    }
                    let dataBox = {
                        "params": {
                            "currentPage": this.query.currentPage,
                            "pageSize": this.query.pageSize,
                            "keyword": this.query.filterType == 1 ? '' : this.query.keyword,
                            "cityId": this.query.cityId,
                            "checkInDate": this.query.checkInDate,
                            "checkOutDate": this.query.checkOutDate,
                            "starTypes": hotelStar,
                            "positionCoordinate": this.query.pt || '',
                            "distance": this.query.d,
                            "sort": this.query.sort || 0,
                            "minPrice": this.query.minPrice || '',
                            "maxPrice": this.query.maxPrice || '',
                            "isBreakfast": this.query.isBreakfast || 0,
                            "isNegotiated": this.query.agreementEnable || 0,
                            "isDifference": this.query.differenceEnable || 0,
                            "filterItem": this.query.activeFilter || {},
                            "applyJourneyId": this.query.journeyId ? this.query.journeyId : '',
                            "evectionNo": this.query.evectionNo,
                            "evectionType": parseInt(this.query.evectionType),
                            "cityName": this.query.cityName,
                            "lacationName": this.query.cityName,
                        }
                    };
                    let [err, hotelListResult] = await awaitWrap(consumer_hotel_search_hotelListV2(dataBox));

                    if (err) {
                        if (err.data.code === '200041') {
                            this.isNoMore = true;
                            this.pageCount = 0;
                            this.total = 0;
                            this.list = [];
                            this.searchLoading = false;
                            return;
                        } else if (err.data.code === '200042') {
                            this.cityNotValid = true;
                            this.cityNotValidMsg = err.data.msg;
                            this.isNoMore = true;
                            this.pageCount = 0;
                            this.total = 0;
                            this.list = [];
                            this.searchLoading = false;
                            return;
                        }
                    }

                    this.pageCount = hotelListResult.pageResult.pageCount ? hotelListResult.pageResult.pageCount : 0;
                    this.total = hotelListResult.pageResult.total ? hotelListResult.pageResult.total : 0;
                    const hotelList = hotelListResult.pageResult.pageData ? hotelListResult.pageResult.pageData : [];
                    this.checkIsNoMore();
                    if (hotelList.length) {
                        hotelList.forEach(item => {
                            item.minPriceRender = true;

                            let distanceText = '';
                            if (item['linearDistance'] !== null && item['linearDistance'] !== '') {
                                distanceText += `距${this.landmark}直线距离`;
                                let linearDistance = parseInt(item['linearDistance']);
                                if (linearDistance < 100) {
                                    distanceText += '不足100米';
                                } else if (linearDistance >= 100 && linearDistance < 1000) {
                                    let linear = Math.floor(linearDistance / 100) * 100;
                                    distanceText += `${linear}米`;
                                } else {
                                    let linear = (linearDistance / 1000).toFixed(1);
                                    distanceText += `${linear}公里`;
                                }
                            }
                            item['distanceText'] = distanceText;
                        })
                        if (this.query.currentPage === 1) {
                            this.list = hotelList;
                        } else {
                            this.list = this.list.concat(hotelList)
                        }

                        this.searchLoading = false
                        const cityId = hotelList[0].cityId;
                        const hotelIds = hotelList.map(item => item.hotelId);

                        hotelIds.forEach(hotelId => {
                            const hoteMinPriceQuery = {
                                applyId: this.query.applyId,
                                evectionNo: this.query.evectionNo,
                                cityId: this.query.cityId,
                                checkInDate: this.query.checkInDate,
                                checkOutDate: this.query.checkOutDate,
                                hotelIds: [hotelId],
                                applyJourneyId: this.query.journeyId ? this.query.journeyId : '',
                            };
                            consumer_hotel_search_getHotelMinPrice(hoteMinPriceQuery).then((hotelMinPriceList) => {
                                const minPriceResults = hotelMinPriceList.minPriceResults;

                                this.list.forEach(item => {
                                    for (let i = 0; i < minPriceResults.length; i++) {
                                        if (item.hotelId === Number(minPriceResults[i].hotelId)) {
                                            this.$set(item, 'price', minPriceResults[i].minPrice)
                                            this.$set(item, 'accord', minPriceResults[i].accord)
                                            this.$set(item, 'hotelParams', {
                                                cityId: this.query.cityId,
                                                evectionNo: this.query.evectionNo,
                                                price: minPriceResults[i].minPrice
                                            })
                                            this.$set(item, 'minPriceRender', false)
                                            break;
                                        }
                                    }

                                    if (item.facilitieList != null) {
                                        let facilitieList
                                        if (item.facilitieList.length > 8) {
                                            facilitieList = item.facilitieList.slice(0, 8);
                                            facilitieList = facilitieList.map(it => it.namecn);
                                        } else {
                                            facilitieList = item.facilitieList.map(it => it.namecn);
                                        }
                                        this.$set(item, 'facilitieStr', facilitieList.join(' | '))
                                    } else {
                                        this.$set(item, 'facilitieStr', '无')
                                    }
                                });
                            })
                        })
                    }
                } catch (error) {
                    console.log(error);
                    return;
                }
                this.searchLoading = false;

                setTimeout(() => {
                    if (this.list.length < 20 && !this.isNoMore) {
                        this.query.currentPage++;
                        this.setList();
                    }
                }, 500)
            }
        },
        //获取酒店推荐列表
        async getRecommendList() {
            this.searchLoading = true;
            try {
                // 关于关键词。 如果关键词选择的是地标类型，则不用传入到接口，只传坐标，如果关键词是纯关键词或者是酒店类型，则需要传入到接口，不传坐标
                let dataBox = {
                    "params": {
                        "currentPage": this.query.currentPage,
                        "pageSize": this.query.pageSize,
                        "keyword": this.query.filterType == 1 ? '' : this.query.keyword,
                        "cityId": this.query.cityId,
                        "checkInDate": this.query.checkInDate,
                        "checkOutDate": this.query.checkOutDate,
                        "starTypes": this.query.hotelStar ? this.query.hotelStar.split(',') : [],
                        "positionCoordinate": this.query.pt || '',
                        "distance": this.query.d,
                        "sort": this.query.sort || 0,
                        "minPrice": this.query.minPrice || '',
                        "maxPrice": this.query.maxPrice || '',
                        "isBreakfast": this.query.isBreakfast || 0,
                        "isNegotiated": this.query.agreementEnable || 0,
                        "isDifference": this.query.differenceEnable || 0,
                        // "filterItem": this.query.activeFilter || {},
                        "applyJourneyId": this.query.journeyId ? this.query.journeyId : '',
                        "evectionNo": this.query.evectionNo,
                        "evectionType": parseInt(this.query.evectionType),
                        "cityName": this.query.cityName,
                        "lacationName": this.query.cityName,
                    }
                };
                let [err, res] = await awaitWrap(consumer_hotel_search_hotelListRecommendV3(dataBox));
                if (err && err.data.code === '200041') {
                    this.recommendList = [];
                    this.searchLoading = false;
                    return;
                }
                const recommendList = res.pageResult.pageCount ? res.pageResult.pageCount : [];
                if (recommendList.length) {
                    recommendList.forEach(item => {
                        item.minPriceRender = true;

                        let distanceText = '';
                        if (item['linearDistance'] !== null && item['linearDistance'] !== '') {
                            distanceText += `距${this.landmark}直线距离`;
                            let linearDistance = parseInt(item['linearDistance']);
                            if (linearDistance < 100) {
                                distanceText += '不足100米';
                            } else if (linearDistance >= 100 && linearDistance < 1000) {
                                let linear = Math.floor(linearDistance / 100) * 100;
                                distanceText += `${linear}米`;
                            } else {
                                let linear = (linearDistance / 1000).toFixed(1);
                                distanceText += `${linear}公里`;
                            }
                        }
                        item['distanceText'] = distanceText;
                    })
                    this.recommendList = recommendList
                    this.searchLoading = false
                    const cityId = recommendList[0].cityId;
                    const hotelIds = recommendList.map(item => item.hotelId);

                    hotelIds.forEach(hotelId => {
                        const hoteMinPriceQuery = {
                            applyId: this.query.applyId,
                            evectionNo: this.query.evectionNo,
                            cityId: cityId,
                            checkInDate: this.query.checkInDate,
                            checkOutDate: this.query.checkOutDate,
                            hotelIds: [hotelId],
                            applyJourneyId: this.query.journeyId ? this.query.journeyId : '',
                        };
                        consumer_hotel_search_getHotelMinPrice(hoteMinPriceQuery).then((hotelMinPriceList) => {
                            const minPriceResults = hotelMinPriceList.minPriceResults;

                            this.recommendList.forEach(item => {
                                for (let i = 0; i < minPriceResults.length; i++) {
                                    if (item.hotelId === Number(minPriceResults[i].hotelId)) {
                                        this.$set(item, 'price', minPriceResults[i].minPrice)
                                        this.$set(item, 'accord', minPriceResults[i].accord)
                                        this.$set(item, 'hotelParams', {
                                            cityId: this.query.cityId,
                                            evectionNo: this.query.evectionNo,
                                            price: minPriceResults[i].minPrice
                                        })
                                        this.$set(item, 'minPriceRender', false)
                                        break;
                                    }
                                }

                                if (item.facilitieList != null) {
                                    let facilitieList
                                    if (item.facilitieList.length > 8) {
                                        facilitieList = item.facilitieList.slice(0, 8);
                                        facilitieList = facilitieList.map(it => it.namecn);
                                    } else {
                                        facilitieList = item.facilitieList.map(it => it.namecn);
                                    }
                                    this.$set(item, 'facilitieStr', facilitieList.join(' | '))
                                } else {
                                    this.$set(item, 'facilitieStr', '无')
                                }
                            });
                        })
                    })
                }
            } catch (error) {
                console.log(error);
                return;
            }
            this.searchLoading = false;
        },
        handleSizeChange(size) {
            this.query.pageSize = size;
            this.query.currentPage = 1;
            this.setList();
        },
        handleCurrentChange(page) {
            this.query.currentPage = page;
            this.setList();
        },
        async onChangeSearchQuery(value) {
            console.log('c', value);
            //转换城市时，需要将已选的位置区域值清空和已选地标关键字清空
            if (value.cityId && Number(value.cityId) !== Number(this.query.cityId)) {
                this.query.cityId = value.cityId;
                this.query.cityName = value.cityName;
                this.query.pt = '';
                this.query.d = '';
                this.query.keyword = '';
                this.query.areaId = '';
                this.query.areaType = '';
                this.query.areaName = '';
                this.query.lineCode = '';
                this.query.stationValue = '';
                //不用手动清空，在下面对用于显示的关键字keyword重新赋值就行
                // this.$refs['search-head'].clearKeyword();
                this.$refs['filter-box'].setValue(this.query);
                this.$refs['filter-box'].clearAll(false);
                this.query.activeFilter = {};
                this.$nextTick(async()=>{
                    let activeFilter = await this.$refs['filter-box'].init();
                    this.query.activeFilter = activeFilter;
                })

                // 更新差标
                if (this.isShowStandard) {
                    this.setStandard();
                }
            }

            //this.keyword:用于页面显示关键字；this.query.keyword:用于搜索的关键字字符串
            //获取用于显示的关键字
            this.keyword = value.keyword || value.keyword === '' ? value.keyword : this.keyword;
            //对用于显示的关键字keyword重新赋值
            this.$refs['searchBox'].setKeyword(this.keyword);


            let oldPt = JSON.parse(JSON.stringify(this.query.pt))
            let oldFilterType = JSON.parse(JSON.stringify(this.query.filterType))
            let oldPtFromType = JSON.parse(JSON.stringify(this.query.pt_from_type))

            //将改变值赋给搜索条件
            this.query = Object.assign({}, this.query, value, {
                currentPage: 1,
            });

            //当前一个pt来自关键字keyword，并且这次来自区域位置组件的areaName为空
            //则需要沿用上一个来自关键字keyword的pt值
            if (((oldPtFromType === '000' && Number(oldFilterType) === 1) || oldPtFromType === '100') && value.pt_from_type === '200' && value.areaName === '') {
                this.query.pt = oldPt;
            }

            //当前一个pt来自区域位置，并且这次来自关键字组件的keyword为非地标类型
            //则需要沿用上一个来自区域位置的pt值
            if (oldPtFromType === '200' && value.pt_from_type === '100' && Number(value.filterType) === 0) {
                this.query.pt = oldPt;
            }

            /***
             如果已选的关键字是地标类型，则
             1.离我半径距离d需清空 --- 2022-08-02修改为区域位置和d可同时选中，无需相互清空。
             2.搜索条件中的keyword需要传''
             3.已选的位置区域值清空
             4.landmark为此时的关键字
             ***/
            if (Number(value.filterType) === 1) {
                // 1.离我半径距离d需清空
                // this.query.d = '';
                // this.$refs['dropdown-menu'].clearSelectedDistance();

                // 2.搜索条件中的keyword需要传''
                this.query.keyword = '';

                // 3.已选的位置区域值清空
                this.query.areaId = '';
                this.query.areaName = '';
                this.$refs['filter-box'].clearSelectedArea();

                //4.landmark为此时的关键字
                this.landmark = value.keyword;
            }

            /***
             *2022-08-02修改为区域位置和d可同时选中，无需相互清空。以下作废。
             如果选了离我半径距离d，则
             1.已选的位置区域值清空
             2.已选的坐标类型的关键字需清空
             ***/
            // if (value.d) {
            // 1.已选的位置区域值清空
            // this.query.areaId = '';
            // this.query.areaName = '';
            // this.$refs['dropdown-menu'].clearSelectedArea();

            // 2.已选的坐标类型的关键字需清空
            // if (Number(this.query.filterType) === 1) {
            //     this.keyword = '';
            //     this.query.pt = '';
            //     this.query.keyword = '';
            //     this.$refs['search-head'].clearKeyword();
            // }
            // }

            /***
             如果位置区域，则
             1.离我半径距离d清空 --- 2022-08-02修改为区域位置和d可同时选中，无需相互清空。
             2.已选的坐标类型的关键字需清空
             3.landmark为此时的位置区域名称
             ***/
            if (value.areaName) {
                // 1.离我半径距离d需清空
                // this.query.d = '';
                // this.$refs['dropdown-menu'].clearSelectedDistance();

                // 2.已选的坐标类型的关键字需清空
                if (Number(oldFilterType) === 1) {
                    this.keyword = '';
                    // this.query.pt = '';//此时pt已是已选区域位置的pt，所以不需要清空
                    this.query.keyword = '';
                    this.$refs['searchBox'].clearKeyword();
                }

                //3.landmark为此时的位置区域名称
                this.landmark = value.areaName;
            }

            //重组地址栏的值
            // setTimeout(() => {
            //   if (this.query.evectionType === 1) {
            //     //因私
            //     this.updateUrlForPrivate();
            //   } else {
            //     //因公
            //     this.updateUrlForOfficial();
            //   }
            // }, 500)

            this.list = [];
            this.recommendList = [];
            this.isNoMore = false;
            console.log('触发刷新');
            await this.setList();
            if (this.list && this.list.length === 1) {
                await this.getRecommendList();
            }
            this.query.pt_from_type = value.pt_from_type ? value.pt_from_type : this.query.pt_from_type;

            //当前一个pt来自关键字keyword，并且这次来自区域位置组件的areaName为空
            //则需要沿用上一个来自关键字keyword的pt值,所以pt_from_type仍为'100'
            if (((oldPtFromType === '000' && Number(oldFilterType) === 1) || oldPtFromType === '100') && value.pt_from_type === '200' && value.areaName === '') {
                this.query.pt_from_type = '100';
            }

            //当前一个pt来自区域位置，并且这次来自关键字组件的keyword为非地标类型
            //则需要沿用上一个来自区域位置的pt值,所以pt_from_type仍为'200'
            if (oldPtFromType === '200' && value.pt_from_type === '100' && Number(value.filterType) === 0) {
                this.query.pt_from_type = '200';
            }
        },
        async setTravelPolicyTips() {
            const {
                evectionNo,
                cityId,
                evectionType,
            } = this.query;
            if (parseInt(evectionType) === 1) return;
            let query = {
                businessType: 3,
                evectionNo: evectionNo,
                hotelCityId: cityId,
                applyJourneyId: this.query.journeyId ? this.query.journeyId : '',
                price: '',
            };
            try {
                let travelPolicyTipsResult = await consumer_web_policyConfig_travelPolicyTips(query);
                if (travelPolicyTipsResult) {
                    travelPolicyTipsResult = travelPolicyTipsResult.datas.trainsOrHotelMsg;
                    if (travelPolicyTipsResult) {
                        travelPolicyTipsResult = travelPolicyTipsResult.split('-');
                        this.travelPolicyTips = {
                            standard: travelPolicyTipsResult[0],
                            people: travelPolicyTipsResult[1],
                        };
                    } else {
                        this.travelPolicyTips = false;
                    }
                }
            } catch (error) {
                console.log(error);
            }

        },
        setEvectionDetail() {
            const {evectionNo, evectionType} = this.query;
            if (parseInt(evectionType) === 1) return;
            consumer_journey_getEvectionDetail({evectionNo, pageType: 1, personType: 2})
                .then(data => {
                    this.evectionDetail = data.datas;
                });
        },
        onPopoverShow(item) {
            this.popoverLoading = true;
            let params = {
                user: {
                    id: item.userId
                }
            };
            consumer_web_policyConfig_myTravelPolicy(params).then(res => {
                this.popoverLoading = false;
                this.myTravelPolicy = res.datas;
            }).catch(() => {
                this.popoverLoading = false;
                this.myTravelPolicy = {};
            });
        },
        onDetail(row) {
            if (row.minPriceRender || row.price <= 0) {
                return;
            }
            const {
                cityId,
                applyId,
                cityName,
                sort,
                checkInDate,
                checkOutDate,
                departTime,
                returnTime,
                filterType,
                keyword,
                pt,
                workTravelType,
                journeyType,
                unlimitedJourney,
                shareJourneyId,
                shareEvectionNo,
            } = this.query;
            let queryParams = {
                cityId,
                cityName,
                applyId,
                hotelId: row.hotelId,
                hotelName: row.name,
                hotelNameEn: row.name,
                star: row.starType,
                hotelAddr: row.address,
                bizName: row.bizName || '',
                facilities: row.facilities,
                phone: row.contactNumber,
                checkInDate,
                checkOutDate,
                departTime,
                returnTime,
                imageUrl: row.mainSmallImage || row.mainImage,
                filterType,
                keyword,
                pt,
                journeyId: parseInt(this.query.journeyId) || 0,
                minPrice: row.price,
            }
            const key = 'params';
            const encrypted = aes_encrypt({
                data: JSON.stringify(queryParams),
                key,
            });
            console.log(this.shareJourneyId, this.shareEvectionNo)
            let query = {
                journeysId: this.$route.query.journeysId ? this.$route.query.journeysId : '',
                params: encrypted,
                evectionRequired: this.query.evectionRequired,
                evectionType: this.query.evectionType,
                workTravelType,
                journeyType,
                unlimitedJourney,
                sort,
                shareJourneyId: this.shareJourneyId,
                shareEvectionNo: this.shareEvectionNo,
            };

            if (Number(this.query.evectionType) === 1) {
                this.$router.push({
                    name: 'admin-hotel-detail',
                    query: query
                });
            } else {
                console.log('query', query);
                this.$router.push({
                    name: 'admin-hotel-detail',
                    query,
                });
            }
        },
        async onShowEditPeople() {
            const {evectionNo} = this.query;
            let query = {
                evectionNo,
                approveType: 1,
            };
            const result = await consumer_apply_myApplyList(query);
            let list = result.datas.list;
            if (list.length) {
                this.allPeopleList = list[0].staffList;
            }
            const travellerList = this.evectionDetail.travellerList ? this.evectionDetail.travellerList : [];
            this.peopleCheckList = travellerList.map(item => item.userId);
            this.showEditPeople = true;
        },
        onSaveEditPeople() {
            this.showEditPeople = false;
            const {evectionNo} = this.query;
            let query = {
                evectionNo,
                userIdList: this.peopleCheckList,
            };
            consumer_journey_saveOrderMsg(query)
                .then(() => {
                    this.setEvectionDetail();
                    this.setTravelPolicyTips();
                });
        },
        onCityChange(val) {
            this.query.cityId = val.cityId;
            this.query.cityName = val.cityName;
            this.query.pt = '';
            this.query.keyword = '';
            this.$refs['filter-box'].delSelectedArea();
        },
        //搜索栏组件中关键字改变时
        onKeywordChange(val) {
            if (val.filterType === 1) {
                this.query.areaId = '';
                this.query.areaName = '';
                this.$refs['filter-box'].delSelectedAreaNoEmit();
            }
        },
        //筛选栏组件中区域位置改变时
        onAreaChange() {
            if (this.query.filterType === 1) {
                this.keyword = '';
                this.query.keyword = '';
                this.$refs.searchBox.clearKeyword();
            }
        },
        checkEvectionRequired() {
            this.$message({
                type: "warning",
                message: '不能修改出差单既定行程'
            })
        },
        async updateEvectionInfo(val) {
            /*  返参： val
                * {
                *   journeyId: string, 行程id
                *   evectionNo： string， 出差单号
                *   workTravelType: number, 因公出差的类型， 1是有选出差单，2是没有
                *   journeyType: number, 0 普通单， 1 不限行程单
                *   editType: string, 编辑类型，'000' 变更出差单号(无出差单->有出差单 或者 有出差单->有出差单), '001': 变更出差单（有出差单->无出差单）  '002' 修改原本出差单内容
                *   travelCurrent： object, 当前选中的出差单， 如果没有选出差单则返回null
                * }
            * */

            let curTravel;
            let {
                applyId,
                cityId,
                cityName,
                checkInDate,
                checkOutDate,
                evectionRequired,
                unlimitedJourney,
            } = this.query;

            let queryParams = {
                cityId,
                cityName,
                unlimitedJourney,
                redirectType: '000',
                keyword: this.keyword,
                hotelStar: this.hotelStar,
                pt: this.pt,
                filterType: this.filterType
            };

            if (val.travelCurrent) {
                curTravel = val.travelCurrent.active;
                //因公，有出差单

                queryParams = {
                    cityId: curTravel.toCityId || this.query.cityId,
                    cityName: curTravel.toCityName || this.query.cityName,
                    checkInDate: curTravel.firstDepartTime,
                    checkOutDate: curTravel.firstReturnTime,
                    departTime: curTravel.departTime,
                    returnTime: curTravel.returnTime,
                    applyId: curTravel.id,
                    journeyId: parseInt(val.journeyId) || 0,
                    shareJourneyId: parseInt(val.shareJourneyId || 0),
                    shareEvectionNo: val.shareEvectionNo,
                }

                //改变城市时，去掉关于关键字的相关搜索条件
                if (applyId && Number(val.travelCurrent.active.id) !== Number(applyId)) {
                    queryParams.filterType = 0;
                    queryParams.keyword = '';
                    queryParams.pt = '';
                }

            } else {
                //因公，无出差单
                //无出行时间范围
                queryParams.applyId = 0;
                queryParams.journeyId = 0;
                queryParams.checkInDate = checkInDate;
                queryParams.checkOutDate = checkOutDate;
                queryParams.departTime = '';
                queryParams.returnTime = '';
            }

            const encrypted = aes_encrypt({
                data: JSON.stringify(queryParams),
                key: 'params',
            });

            let urlParams = {
                params: encrypted,
                evectionNo: val.evectionNo,
                evectionType: 2, // 1是个人出差，2是出差单出差
                evectionRequired: evectionRequired,
                workTravelType: val.workTravelType, // 因公出差的类型， 1是有选出差单，2是没有
                journeyType: val.journeyType, // 0 普通单， 1 不限行程单
                unlimitedJourney: unlimitedJourney, //0 限制行程， 1 不限行程
                shareJourneyId: parseInt(val.shareJourneyId || 0),
                shareEvectionNo: val.shareEvectionNo,
            }

            // 组装当前页面url
            let href = location.href.split('?')[0]
            let newUrl = href + '?'
            for (let i in urlParams) {
                newUrl += `${i}=${urlParams[i]}&`
            }
            history.replaceState(null, null, newUrl)

            if (val.editType === '000') {
                location.reload();
            }

            if (val.editType !== '000') {
                this.isNoMore = false;
                this.workTravelType = parseInt(val.workTravelType);
                this.journeyType = parseInt(val.journeyType);
                this.shareJourneyId = parseInt(val.shareJourneyId || 0);
                this.shareEvectionNo = val.shareEvectionNo;

                this.query.shareJourneyId = parseInt(val.shareJourneyId || 0);
                this.query.shareEvectionNo = val.shareEvectionNo;
                this.query.applyId = queryParams.applyId
                this.query.evectionNo = val.evectionNo,
                    this.query.workTravelType = val.workTravelType
                this.query.journeyType = val.journeyType
                this.query.journeyId = parseInt(queryParams.journeyId) || 0
                this.query.currentPage = 1
                this.list = [];
                this.recommendList = [];

                this.getEvectionType();
                this.canChangeFactor();
                this.setStandard();
                await this.setList();
                if (this.list && this.list.length === 1) {
                    await this.getRecommendList();
                }
                if (this.productList && this.productList.length === 1) {
                    await this.getRecommendList();
                }
            }
        },
        toEditEvectionInfo(popupType) {
            console.log('travelPopup', {
                popupType,
                evectionNo: this.query.evectionNo,
                evectionRequired: parseInt(this.query.evectionRequired) || 0,
                journeyId: parseInt(this.query.journeyId) || 0,
                businessRuleControl: this.businessRuleControl,
                hotelInfo: {
                    cityId: this.query.cityId,
                    checkInTime: this.query.checkInDate,
                    checkOutTime: this.query.checkOutDate,
                    shareJourneyId: this.query.shareJourneyId,
                    shareEvectionNo: this.query.shareEvectionNo,
                },
            })
            this.$refs['travelPopup'].init({
                popupType,
                evectionNo: this.query.evectionNo,
                evectionRequired: parseInt(this.query.evectionRequired) || 0,
                journeyId: parseInt(this.query.journeyId) || 0,
                businessRuleControl: this.businessRuleControl,
                hotelInfo: {
                    cityId: this.query.cityId,
                    checkInTime: this.query.checkInDate,
                    checkOutTime: this.query.checkOutDate,
                    shareJourneyId: this.query.shareJourneyId,
                    shareEvectionNo: this.query.shareEvectionNo,
                },
            })
        },
        handleScroll() {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            //变量windowHeight是可视区的高度
            let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            //变量scrollHeight是滚动条的总高度
            let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
            if (scrollTop + windowHeight === scrollHeight || scrollTop + windowHeight > scrollHeight) {
                this.query.currentPage++;
                this.setList();
            }
        }
    },
    created() {
        this.evectionRequired = this.$route.query.evectionRequired === undefined ? 0 : parseInt(this.$route.query.evectionRequired)
        this.initQuery();
        this.setTravelPolicyTips();
        this.setEvectionDetail();
    },
    mounted() {
        this.initSearchBoxInfo();
        this.getEvectionType();
        this.canChangeFactor();
    },
    async activated() {
        await this.getUserInfo();
        await this.refundChangePolicyGet();
        this.setStandard()
        this.$refs['filter-box'].setValue(this.query)
        let activeFilter = await this.$refs['filter-box'].init();
        this.query.activeFilter = activeFilter;
        this.setList().then(() => {
            window.addEventListener('scroll', this.handleScroll, true);
            if (this.list && this.list.length === 1) {
                this.getRecommendList();
            }
        })
    },
    deactivated() {
        window.removeEventListener('scroll', this.handleScroll, true);
    }
}
